import axios from 'axios';

//const host = 'https://localhost:5001';
//const host = 'https://targetspecifications.artworkgeneration.cimpress.io';
const hostDev = 'https://dev.targetspecifications.artworkgeneration.cimpress.io';
const hostProd = 'https://targetspecifications.artworkgeneration.cimpress.io';
//const hostProd = 'https://localhost:5001';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Caller-Id': 'content-catalog-ui'
};

function createRequest(productKey, options, isDev, productVersion = 0) {
  var optionsList = '';
  for (var key in options) {
    var option = `optionSelections[${encodeURIComponent(key)}]=${encodeURIComponent(options[key])}&`;
    optionsList = optionsList + option;
  }

  optionsList = optionsList.substring(0, optionsList.length - 1);

  const host = isDev ? hostDev : hostProd;

  const url = `${host}/api/v5/productSpecifications/${productKey}/version/${productVersion}?${optionsList}`;
  return isDev ? `${url}&useDevProductContentService=true` : url;
}

export async function getProductSpecs(productKey, options, isDev, productVersion = 0) {
  const url = createRequest(productKey, JSON.parse(options), isDev, productVersion);
  try {
    const result = await axios.get(url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}
