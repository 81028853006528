import React from 'react';

import { Form } from 'react-bootstrap';


const Ducs = ( { ducs, setDucs, text } ) => {

    const localSetDuc = (ducsText) =>
    {
        setDucs(ducsText);
    }

    return (<div>
        <h4 className={"mt-5"}>{text}</h4>
        <Form>
           <Form.Control className={"mb-2"} type={"text"} value={ducs} onChange={(e) => localSetDuc(e.target.value)} id={"ducs"} />
      </Form>
    </div>);
}


export default Ducs;
