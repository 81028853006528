import axios from 'axios';

class DtrContextImplementation {
  constructor(context) {
    this.culture = context.culture || 'en-IE';
    this.authToken = context.authToken || '';
    this.environment = context.environment || 'PROD';
    this.requester = context.requester || 'UI Catalog Tool';
    this.correlationId = context.correlationId || generateGuid();
    this.fillEmptyTextElementsWithLabels = context.fillEmptyTextElementsWithLabels || false;
  }
}

const devHost = 'https://dev.realization.artworkgeneration.cimpress.io';
const prodHost = 'https://prod.realization.artworkgeneration.cimpress.io';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getEnvironmentUrl(environment) {
  return environment === 'DEV' ? devHost : prodHost;
}

function getRealizationUrl(encodedDti, requestContext, fillEmptyTextElementsWithLabels) {
  const context = new DtrContextImplementation(requestContext);
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v3/realizeToken?token=${encodedDti}&requester=${encodeURIComponent(context.requester)}&culture=${encodeURIComponent(
    context.culture
  )}&fillDefaultText=${fillEmptyTextElementsWithLabels}`;
  return url;
}

export async function realize(encodedDti, requestContext, fillEmptyTextElementsWithLabels) {
  const url = getRealizationUrl(encodedDti, requestContext, fillEmptyTextElementsWithLabels);

  try {
    const result = await axios.get(url, { headers });

    return {
      realizedDocumentUrl: url,
      document: result.data
    };
  } catch (error) {
    console.error(error);
  }
}
