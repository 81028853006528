import React, { useState, useContext } from 'react';
import { Button, ButtonGroup, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import DocumentDetails from './document-details';
import DocumentUrl from './document-url';
import TextAssets from './text-assets';
import Ducs from './ducs'
import SlidingSwitch from './sliding-switch';
import { AppContext } from '../App';


import DtcResult from './dtc-result';
import ProductOptions from './product-options';
import Culture from './culture'
import TssResult from './tss-result';
import classNames from 'classnames';

const CrossSell = () => {
  const { isTssDev, 
    setIsTssDev, 
    isDtcDev, 
    setIsDtcDev, 
    isDtcFeatureFlagEnabled, 
    setIsDtcFeatureFlagEnabled, 
    ducs, 
    setDucs, 
    sourceDucs, 
    setSourceDucs } = useContext(AppContext);
  const [selectedResultsTab, setSelectedResultsTab] = useState('Document');

  const handleTssEnvironmentChange = function (event) {
    setIsTssDev(!event.target.checked);
  };

  const handleDtcEnvironmentChange = function (event) {
    setIsDtcDev(!event.target.checked);
  };

  const handleDtcFeatureFlagChange = function (event) {
    setIsDtcFeatureFlagEnabled(event.target.checked)
  }

  return (
    <Container className={'mb-3'}>
      <h1 className={'my-4'}>Cross Sell</h1>
      <Row>

      </Row>
      <Row>
        <Col sm={12} md={12} lg={6}>
          <Jumbotron className={'py-5 bg-white'}>
            <DocumentUrl />
            <Culture />
            <ProductOptions />
            <TextAssets />
            <Ducs ducs={ducs} setDucs={setDucs} text="Target DUCs" />
            <Ducs ducs={sourceDucs} setDucs={setSourceDucs} text="Source DUCs" />
          </Jumbotron>
        </Col>

        <Col sm={12} md={12} lg={6}>
          <SlidingSwitch
            style={{ backgroundColor: 'white', zIndex: 1000 }}
            name="environment-switch-tss"
            leftLabel="TSS DEV"
            rightLabel="PROD"
            checked={!isTssDev}
            onChange={handleTssEnvironmentChange}
          />
          <SlidingSwitch
            style={{ backgroundColor: 'white', zIndex: 1000 }}
            name="environment-switch-dtc"
            leftLabel="DTC DEV"
            rightLabel="PROD"
            checked={!isDtcDev}
            onChange={handleDtcEnvironmentChange}
          />
          <SlidingSwitch
            style={{ backgroundColor: 'white', zIndex: 1000 }}
            name="feature-flag-switch-dtc"
            leftLabel="DTC Set feature flag: NO "
            rightLabel=" YES"
            checked={isDtcFeatureFlagEnabled}
            onChange={handleDtcFeatureFlagChange}
          />
          <div className="d-flex flex-column mt-4">

            <ButtonGroup size={'lg'}>
              {
                ['Document', 'TSS', 'DTC'].map(tab =>
                  <Button key={tab} className={'resultTabsButton'} active={selectedResultsTab === tab}
                    onClick={() => setSelectedResultsTab(tab)}>{tab}</Button>
                )
              }
            </ButtonGroup>
          </div>
          <div className={'px-2'}>
            <DocumentDetails className={classNames(selectedResultsTab !== 'Document' && 'd-none')} />
            <TssResult className={classNames(selectedResultsTab !== 'TSS' && 'd-none')} />
            <DtcResult className={classNames(selectedResultsTab !== 'DTC' && 'd-none')} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CrossSell;
