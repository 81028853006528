import React, { useContext } from 'react';
import { AppContext } from '../App';
import { Form } from 'react-bootstrap';




const TextAssets = () => {

    const { textAssets, setTextAssets } = useContext(AppContext);

    return (<div>
        <h4 className={"mt-5"}>Assets</h4>
        <Form>
            <Form.Control className={"mb-2"} 
                rows='10' 
                cols='30' 
                as="textarea" placeholder={"https://"} value={textAssets}
                onChange={(e) => setTextAssets(e.target.value)} />
      </Form>
    </div>);
}


export default TextAssets;
