import React, { useEffect, useState } from "react";
import { initializeAuth } from "./Auth";
import CrossSell from "./components/cross-sell";
import Footer from "./components/footer";

export const AppContext = React.createContext({});

function App() {
  useEffect(() => {
    initializeAuth()
  }, []);

  const json = {
    "texts": {
      "familyname": "Family Name123",
      "address1": "Address Line 111",
      "address2": "Address Line 222"
    },
    "images": [
      {
        "hasPriority": false,
        "printUrl": "https://uploads.documents.cimpress.io/v1/uploads/22e67bfc-44a8-4130-992e-13152ea256b1~100/print?tenant=designtechprod",
        "previewUrl": "https://uploads.documents.cimpress.io/v1/uploads/22e67bfc-44a8-4130-992e-13152ea256b1~100/preview?tenant=designtechprod",
        "originalSourceUrl": "https://uploads.documents.cimpress.io/v1/uploads/02063cfb-3dbe-4baa-8309-c29b37ea666f~100/original?tenant=designtechprod"
      }
    ]
  };

  const [documentUrl, setDocumentUrl] = useState("");
  const [isDocumentPhotoProduct, setIsDocumentPhotoProduct] = useState(false);
  const [isDocumentLogoProduct, setIsDocumentLogoProduct] = useState(false);

  const [isTargetPhotoProduct, setIsTargetPhotoProduct] = useState(false);
  const [isTargetLogoProduct, setIsTargetLogoProduct] = useState(false);

  const [productVersion, setProductVersion] = useState(0);


  const [textAssets, setTextAssets] = useState(JSON.stringify(json, null, 2));

  const [productOptionCombination, setProductOptionCombination] = useState(
    null
  );
  const [tssResponse, setTssResponse] = useState(null);
  const [dtcResponse, setDtcResponse] = useState(null);
  const [selectedCulture, setSelectedCulture] = useState("en-IE");
  const [templateCulture, setTemplateCulture] = useState(null);
  const [ducs, setDucs] = useState('');
  const [sourceDucs, setSourceDucs] = useState("_Any_");
  const [isTssDev, setIsTssDev] = useState(false);
  const [isDtcDev, setIsDtcDev] = useState(false);
  const [isDtcFeatureFlagEnabled, setIsDtcFeatureFlagEnabled] = useState(false);
  const [isFilledWithTemplateLabels, setIsFilledWithTemplateLabels] = useState(false);

  const context = {
    documentUrl,
    setDocumentUrl,
    isDocumentPhotoProduct,
    isDocumentLogoProduct,
    setIsDocumentPhotoProduct,
    setIsDocumentLogoProduct,
    textAssets,
    setTextAssets,
    productOptionCombination,
    setProductOptionCombination,
    isTargetPhotoProduct,
    setIsTargetPhotoProduct,
    isTargetLogoProduct,
    setIsTargetLogoProduct,
    tssResponse,
    setTssResponse,
    dtcResponse,
    setDtcResponse,
    selectedCulture,
    setSelectedCulture,
    templateCulture,
    setTemplateCulture,
    ducs,
    setDucs,
    isTssDev,
    setIsTssDev,
    isDtcDev,
    setIsDtcDev,
    isDtcFeatureFlagEnabled,
    setIsDtcFeatureFlagEnabled,
    sourceDucs,
    setSourceDucs,
    productVersion,
    setProductVersion,
    isFilledWithTemplateLabels,
    setIsFilledWithTemplateLabels,
  };

  return (
    <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <div className={"flex-grow-1"}>
        <AppContext.Provider value={context}>
          <CrossSell />
        </AppContext.Provider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
