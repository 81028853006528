import axios from 'axios';

const devHost = 'https://dev.catalog.artworkgeneration.cimpress.io';
//const devHost = 'https://localhost:5002';
const prodHost = 'https://catalog.artworkgeneration.cimpress.io';

//const host = 'https://localhost:5002';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Caller-Id': 'content-catalog-ui'
};

const SafeParseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

const ParseCSV = (ducs) => {
  const array = ducs.split(',');
  return array.filter(x => x);
}

const createRequest = (documentUrl, isDocumentPhotoProduct, isDocumentLogoProduct, textAssets, targetSpecifications, templateCulture, ducs, sourceDucs) => {

  var tss = !(ducs?.length) ? targetSpecifications.data.targetSpecifications : { ...targetSpecifications.data.targetSpecifications, ducIds: ParseCSV(ducs) };
  const textAssetsParsed = SafeParseJson(textAssets);
  return {
    _links: {
      document: {
        href: documentUrl
      }
    },
    culture: templateCulture,
    sourceSpecification: {
      isEligibleForPhotoTransfer: isDocumentPhotoProduct,
      isEligibleForLogoTransfer: isDocumentLogoProduct,
      ducIds: ParseCSV(sourceDucs)
    },
    assets: textAssetsParsed,
    // {
    //   texts: textAssetsParsed['textAssets'],
    //   images: textAssetsParsed['images']

    // },
    targetSpecifications: [tss]
  };
};

export async function search(documentUrl, isDocumentPhotoProduct, isDocumentLogoProduct, textAssets, targetSpecifications, templateCulture, ducs, sourceDucs, isDev, isDtcFeatureFlagEnabled) {
  const url = `${isDev ? devHost : prodHost}/api/v5/catalogEntries:search?debug=true${isDtcFeatureFlagEnabled ? '&useResizedTemplateMatcher=true':''}`;

  try {
    const request = createRequest(documentUrl, isDocumentPhotoProduct, isDocumentLogoProduct, textAssets, targetSpecifications, templateCulture, ducs, sourceDucs);
    const result = await axios.post(url, request, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}
