import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const getProductCurrentVersion = async (productKey) => {
  const url = `https://catalog-transition.products.vpsvc.com/api/v2/products/${productKey}/versions/current?requestor=tss-ui-tool`;

  try {
    const result = await axios.get(url, { headers });
    return result.data;
  } catch (error) {
    throw error;
  }
}