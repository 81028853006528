import React from 'react';
import '../shared.scss';

const SlidingSwitch = ({ name, leftLabel, rightLabel, onChange, checked, style }) => (
  <div className="form-group switch" style={{ marginBottom: '.65em' }}>
    {leftLabel && <span className="title left">{leftLabel}</span>}
    <label htmlFor={name} className="switch">
      <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />
      <span className="slider round" />
    </label>
    {rightLabel && <span className="title left">{rightLabel}</span>}
  </div>
);

export default SlidingSwitch;