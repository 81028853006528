import React, { useContext } from 'react';
import { AppContext } from '../App';
import { Form } from 'react-bootstrap';


const Culture = () => {
    const { templateCulture, setTemplateCulture } = useContext(AppContext);

    return (
        <>
            <h4 className={"mt-0"}>Culture</h4>
            <Form>
                <Form.Control className={"mb-2"} type={"text"} value={templateCulture} onChange={(e) => setTemplateCulture(e.target.value)} id={"culture"} />
            </Form>
        </>
    );
}

export default Culture