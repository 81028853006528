const udsInstructionsPath = 'https://uds.documents.cimpress.io/v3/instructions:preview';
const mcpRenderingHostPath = 'https://rendering.documents.cimpress.io/v1/vp/preview?width=1000';

export const createInstructionsUrl = (documentUrl, slots) => {
  const encodedDocumentUrl = encodeURIComponent(documentUrl);

  const slotsPrefix = slots.length === 0 ? '' : `&surfaceOrdinals=${encodeURIComponent(slots.join(','))}`;
  const udsUrl = `${udsInstructionsPath}?documentUri=${encodedDocumentUrl}${slotsPrefix}`;

  return udsUrl;
};

export const createMcpRenderingUrl = (instructionsUrl) => {
  const encodedDocumentUrl = encodeURIComponent(instructionsUrl);

  const udsUrl = mcpRenderingHostPath + '&instructions_uri=' + encodedDocumentUrl;

  return udsUrl;
};
