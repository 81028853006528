import React, { useContext, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { getProductCurrentVersion } from '../api/product-api';
import { AppContext } from '../App';
import { getProductExamples } from '../tools/data-reader';
import { VPSelector, ResourceType } from '@cimpress-technology/generic-selector';
import { auth } from '../Auth';

const ProductOptions = () => {
  const { setProductOptionCombination, setIsTargetPhotoProduct, setIsTargetLogoProduct, setProductVersion, productVersion } = useContext(AppContext);
  const [vpSelectorInst, setVpSelectorInst] = useState();
  const [productId, setProductId] = useState("PRD-RWLCHPA0");
  const [examples, setExamples] = useState([]);
  const [dssUrl, setdssUrl] = useState(null);
  const [dssDesignDocumentUrl, setDssDesignDocumentUrl] = useState(null);
  const [dssDesignViewssUrl, setDssDesignViewssUrl] = useState(null);
  const DEFAULT_QUANTITY = 100;

  useEffect(() => {
    if (productId) {
      getProductCurrentVersion(productId)
        .then(data => setProductVersion(data.version))
        .catch(err => console.error('Error calling product version service by product key:', err));
    }
  }, [productId, setProductVersion]);

  const onChange = (state) => {
    const newOptions = vpSelectorInst.getUserSelections();
    if (newOptions) {
      const newProductOptions = {
        productKey: productId,
        productOptions: JSON.stringify(newOptions)
      };

      const dssUrl = `https://design-specifications.design.vpsvc.com/designSpecifications/products/${newProductOptions.productKey}?selectedOptions=${newProductOptions.productOptions}&culture=en-ie`;
      setProductOptionCombination(newProductOptions);
      setdssUrl(dssUrl);

      const optionsQueryString = encodeURI(Object.entries(newOptions).map(e => `optionSelections[${e[0]}]=${e[1]}`).join('&'));

      const dssDesignDocumentsUrl = `https://design-specifications.design.vpsvc.com/v2/designDocuments/products/${newProductOptions.productKey}/${productVersion}?${optionsQueryString}&fullBleed=false&colorMode=print&culture=en-ie&requester=cross-sell-ui`;
      const dssDesignViewssUrl = `https://design-specifications.design.vpsvc.com/v2/designViews/products/${newProductOptions.productKey}/${productVersion}?${optionsQueryString}&culture=en-ie&requester=cross-sell-ui`;

      setDssDesignDocumentUrl(dssDesignDocumentsUrl);
      setDssDesignViewssUrl(dssDesignViewssUrl)
    } else {
      setProductOptionCombination(null);
    }
  };

  const productIdSet = () => productId !== null && productId.length > 0;

  // load examples
  useEffect(() => {
    getProductExamples().then(data => {
      setExamples(data);
    })
  }, []);

  const handleExampleClick = (example) => {
    const { key } = example;
    setProductId(key);
  };

  return (
    <>
      <h4 className={"mt-5"}>Product-Options Combination</h4>

      {
        <>
          <Form.Control className={"mb-2"} type={"text"} label="ProductID" value={productId} onChange={(e) => setProductId(e.target.value)} id={"ProductID"} />
          {examples.length > 0 &&
            <div className={"mt-2"}>
              {examples.map(example => <Button key={example.name} className={"mr-1 mb-1"} variant={"inverse"} onClick={() => handleExampleClick(example)}>{example.name}</Button>)}
            </div>
          }
          <VPSelector
            productId={productId}
            authToken={auth.getAccessToken()}
            onChange={(e) => onChange(e.state)}
            onError={error => console.log(error)}
            selectionResource={ResourceType.SURFACE}
            displaySingleValuedAttributes={true}
            theme={{
              globals: {
                primaryActiveColor: "#34495e",
                primaryHoverColor: "#53687d",
              }
            }}
            ref={instance => {
              setVpSelectorInst(instance);
            }}
            attributeConfigurations={{
              quantity: {
                isHidden: true,
                initialSelection: `${DEFAULT_QUANTITY}`
              }
            }}
            errorAlertConfiguration={{
              showErrorAlert: false // setting this to false otherwise VPSelector will show an error even if all product options are correctly selected
            }}
          />

          <div className={"mt-2"}>
            <Form.Check className={"mr-1 mb-1"} type="checkbox" label="Photo Product" onChange={(e) => setIsTargetPhotoProduct(e.target.checked)} />
            <Form.Check className={"mr-1 mb-1"} type="checkbox" label="Logo Product" onChange={(e) => setIsTargetLogoProduct(e.target.checked)} />
          </div>
          {productIdSet() &&
            <>
              <h6 className={"mt-4"}>Product Options</h6>
              <Form>
                <h6 className={"mt-4"}>Product Version</h6>
                <Form.Control className={"mb-2"} type={"text"} label="Product Version" value={productVersion} onChange={(e) => setProductVersion(e.target.value)} id={"productVersion"} />
              </Form>
              <div>
                {dssUrl && (<a href={dssUrl} >Design Specification</a>)}
              </div>
              <div>
                {dssDesignDocumentUrl && (<a href={dssDesignDocumentUrl} >DSS V2 Design Documents</a>)}
              </div>
              <div>
                {dssDesignViewssUrl && (<a href={dssDesignViewssUrl} >DSS V2 Design Views</a>)}
              </div>
            </>
          }
        </>

      }
    </>
  );
};

export default ProductOptions;
