import { centralizedAuth } from '@cimpress/simple-auth-wrapper'

const auth = new centralizedAuth({ clientID: '6mF6oq4rq1PJjoPWxyCmYcZmCHaV7kj6' })

const redirectPath = () => window.location.pathname + window.location.search;

const initializeAuth = () => auth.ensureAuthentication({
    nextUri: redirectPath()
  }).then(() => console.log('logged in')
  ).catch(err => console.error(err));

export { auth, initializeAuth }
