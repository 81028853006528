import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AppContext } from '../App';
import { getDocumentExamples } from '../tools/data-reader';

const DocumentUrl = () => {
  const { documentUrl, setDocumentUrl, setIsDocumentPhotoProduct, setIsDocumentLogoProduct } = useContext(AppContext);

  const [examples, setExamples] = useState([]);

  useEffect(() => {
    getDocumentExamples().then(data => {
      setExamples(data);
    })
  }, []);

  return (
    <>
      <h4 className={"mt-0"}>Document URL</h4>
      <Form>
        <Form.Control className={"mb-2"} type={"text"} placeholder={"https://"} value={documentUrl} onChange={(e) => setDocumentUrl(e.target.value)} id={"document_url"} />
        <Form.Check type="checkbox" label="Base on Photo Product" onChange={(e) => setIsDocumentPhotoProduct(e.target.checked)} />
        <Form.Check type="checkbox" label="Base on Logo Product" onChange={(e) => setIsDocumentLogoProduct(e.target.checked)} />
      </Form>

      {examples.length > 0 &&
        <>
          {examples.map(example =>
            <Button
              key={example.name}
              variant={"inverse"}
              className={"mr-1 mb-1"}
              onClick={() => setDocumentUrl(example.link)}>{example.name}</Button>)}
        </>
      }
    </>
  );
};

export default DocumentUrl;
