import React, { useContext, useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import ReactJson from "react-json-view";
import { realize } from "../api/dtr-api";
import SlidingSwitch from './sliding-switch';

import { AppContext } from "../App";
import {
  createInstructionsUrl,
  createMcpRenderingUrl,
} from "../tools/url-helper";
import SceneRenderer from "./scene-renderer";

const DtcEntry = ({ data, header }) => {
  const { correlationId, selectedCulture, isFilledWithTemplateLabels, setIsFilledWithTemplateLabels } = useContext(AppContext);

  const [dtrResponse, setDtrResponse] = useState(null);
  const [templateToken, setTemplateToken] = useState("");
  const [previewUrl, setPreviewUrl] = useState();
  const [udsDocumentUrl, setUdsDocumentUrl] = useState("");

  const { quality, editQuality, designTransformationTokenBase64, metadata } = data;

  useEffect(() => {
    realize(designTransformationTokenBase64, { culture: selectedCulture }, isFilledWithTemplateLabels).then(
      (response) => {
        if (!response) {
          return;
        }
        setDtrResponse(response);

        const udsDocumentUrl = createInstructionsUrl(
          response.realizedDocumentUrl,
          []
        );
        const previewUrl = createMcpRenderingUrl(udsDocumentUrl);
        const token = parseTemplateToken(response);

        setUdsDocumentUrl(udsDocumentUrl);
        setTemplateToken(token);
        setPreviewUrl(previewUrl);
      }
    );
  }, [
    correlationId,
    selectedCulture,
    quality,
    designTransformationTokenBase64,
    isFilledWithTemplateLabels
  ]);

  const handleRealizeDocumentWithFilledTemplateLabels = function (event) {
    setIsFilledWithTemplateLabels(event.target.checked);
  };

  const parseTemplateToken = (dtrResult) => {
    const frontPanel = dtrResult.document.document.panels.filter(
      (p) => p.name === "Front"
    )[0];

    if (frontPanel && dtrResult.document.metadata.documentSources) {
      const frontPanelMetadata = dtrResult.document.metadata.documentSources.panels.filter(
        (p) => p.id === frontPanel.id
      )[0];

      if (
        frontPanelMetadata &&
        frontPanelMetadata.source === "TEMPLATE_TOKEN"
      ) {
        return frontPanelMetadata.data;
      }
    }

    return "";
  };

  return (
    <div className={"mb-5"}>
      <h5 className={"mt-4"}>{header} - <i>{metadata.matcherId}</i></h5>

      <h6 className={"mt-4"}>Quality: <Badge variant={quality > 0 ? "success" : "danger"}>{quality}</Badge></h6>

      <h6 className={"mt-4"}>Edit Quality:
        {
          editQuality > 0 && editQuality > quality &&
          (<Badge variant={"warning"}>{editQuality}</Badge>)
        }
      </h6>


      {quality === 0 && <SceneRenderer udsDocumentUrl={udsDocumentUrl} />}

      {quality > 0 && (
        <>
          <h6 className={"mt-4"}>Template Token: </h6>
          {templateToken ? templateToken : "N/A"}
        </>
      )}

      <h6 className={"mt-4"}>DTI</h6>
      <Form.Control
        as={"textarea"}
        rows={"5"}
        style={{ width: "100%", opacity: 1, color: "inherit" }}
        value={designTransformationTokenBase64}
        readOnly
      />

      <h6 className={"mt-4"}>DTC Response</h6>
      <ReactJson
        src={data}
        defaultValue={{}}
        collapsed={true}
        style={{ wordBreak: "break-all" }}
      />

      {quality > 0 && (
        <>
          <h6 className={"mt-5"}>DTR Response</h6>
          {!dtrResponse ? (
            <>Loading...</>
          ) : (
            <ReactJson
              src={dtrResponse}
              defaultValue={{}}
              collapsed={true}
              style={{ wordBreak: "break-all" }}
            />
          )}

          <h6 className={"mt-5"}>Preview</h6>
          {!dtrResponse ? (
            <>Loading...</>
          ) : (
            <>
              <SlidingSwitch
                style={{ backgroundColor: 'white', zIndex: 1000 }}
                name="fill-with-template-labels"
                leftLabel="Fill with Template Labels"

                checked={isFilledWithTemplateLabels}
                onChange={handleRealizeDocumentWithFilledTemplateLabels}
              />

              <img src={previewUrl} style={{ maxWidth: "100%" }} alt={""} />
            </>
          )}
          <SceneRenderer udsDocumentUrl={udsDocumentUrl} />
        </>
      )}
    </div>
  );
};

export default DtcEntry;
