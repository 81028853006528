import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import axios from "axios";

const SceneRenderer = (props) => {
  const { productOptionCombination, selectedCulture } = useContext(AppContext);
  const [scenePurposes, setScenePurposes] = useState([]);
  const [sceneUrl, setSceneUrl] = useState("");
  const [mpvId, setMpvId] = useState("");
  const [key, setKey] = useState(0);

  useEffect(() => {
    const getScenePurposes = async () => {
      setScenePurposes(
        (
          await axios.get(
            "https://scene-tagging-utility.design.vpsvc.com/api/v1/getAllPurposes"
          )
        ).data
      );
    };
    getScenePurposes();
  }, []);

  useEffect(() => {
    const uri = `https://merchandising-site-experience.prod.merch.vpsvc.com/api/v1/tenant/vistaprint/productKey/${productOptionCombination?.productKey}/mpvs?requestor=UI-Catalog-Tool&cultures=${selectedCulture}`

    const getMpvId = async () => {
      setMpvId(
        (
          await axios.get(uri)
        ).data[selectedCulture]
      )
    }

    getMpvId();
  }, [selectedCulture, productOptionCombination, setMpvId])

  const getScene = async (event) => {
    event.persist();
    const baseUrl = `https://purcs.design.vpsvc.com/api/v0/tenantType/merchants/tenantId/vistaprint-prod/mpvId/${mpvId}/defaultViewImage`;
    const optionSelections = encodeURIComponent(
      productOptionCombination.productOptions
    );
    const sceneUrl = `${baseUrl}?culture=${selectedCulture}&previewInstructionsUri=${encodeURIComponent(
      props.udsDocumentUrl
    )}&purpose=${event.target.value
      }&optionSelections=${optionSelections}&requester=UI-Catalog-Tool&width=400`;
    setSceneUrl(sceneUrl);
    setKey(key + 1);
  };

  return (
    <>
      <h6 className={"mt-5"}>Scene</h6>
      <select onChange={getScene}>
        <option key="empty">-- Select a purpose --</option>
        {scenePurposes.map((scenePurpose) => {
          return (
            <option key={scenePurpose} value={scenePurpose}>
              {scenePurpose}
            </option>
          );
        })}
      </select>
      <img
        key={key}
        src={sceneUrl}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        alt={""}
      />
    </>
  );
};

export default SceneRenderer;
